const games = [
  {
    image: "/games/marioKartTour.webp",
    id: "mario-kart-tour", // used to set the path and to display rigth game into gamepage
    game: "marioKartTour", // used for translation, ex: translate("home.marioKartTour")
    tag: "mariokarttourEN", // used to fetch tweets = require( kart_tour account
    regex: /^[0-9]*$/gm, // used to validate code
    regexFormat: /(\d{4})/g, // used to format the code to a more user friendly version
    length: 12, // length of the code
  },
  {
    image: "/games/pokemonGo.webp",
    id: "pokemon-go",
    game: "pokemonGo",
    tag: "PokemonGoApp",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
    renderCodeHead: true, //rendering code as QR CODE
  },
  {
    image: "/games/appNana.webp",
    id: "app-nana",
    game: "appNana",
    tag: "AppNana_app",
    regexFormat: /(\d{3})/g,
    regex: /^[a-zA-Z0-9]*$/gi,
    length: 9,
  },
  {
    image: "/games/boardKings.webp",
    game: "boardKings",
    id: "board-kings",
    tag: "BoardKingsGame",
    regexFormat: /(\d{3})/g,
    regex: /^[0-9]*$/gm,
    length: 9,
  },
  {
    image: "/games/mySingingMonsters.webp",
    game: "mySingingMonsters",
    id: "my-singing-monsters",
    tag: "SingingMonsters",
    regexFormat: /(\d{2})/g,
    regex: /^[0-9]{8,10}[a-zA-Z]{2}$/gi,
  },
  {
    image: "/games/pokemon-masters-x.webp",
    id: "pokemon-master-ex",
    tag: "PokemonMasters",
    game: "pokemonMastersEX",
    length: 16,
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
  },
  {
    image: "/games/call-of-duty.webp",
    id: "call-of-duty-mobile",
    tag: "playcodmobile",
    game: "callOfDutyMobile",
    regex: /^[a-zA-Z0-9]*$/gi,
    regexFormat: /(\d{3})/g,
    length: 6,
  },
  {
    image: "/games/animalCrossingNewHorizons.webp",
    id: "animal-crossing-new-horizons",
    tag: "animalcrossing",
    game: "animalCrossingNewHorizons",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
  },
  {
    image: "/games/animalCrossingNewHorizonsDodo.webp",
    id: "animal-crossing-new-horizons-dodo",
    game: "animalCrossingNewHorizonsDodo",
    tag: "animalcrossing",
    regex: /^[a-zA-Z0-9]*$/gi,
    length: 5,
  },
  {
    image: "/games/switch.webp",
    id: "nintendo-switch",
    game: "nintendoSwitch",
    tag: "SwitchConsole",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
  },
  {
    image: "/games/pokemonsword.webp",
    game: "pokemonSwordAndPokemonShield",
    id: "pokemon-sword-shield",
    tag: "SwordShieldNews",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
  },
  {
    image: "/games/pokemonHome.webp",
    game: "pokemonHome",
    id: "pokemon-home",
    tag: "PokemonHome_",
    regexFormat: /(\d{4})/g,
    regex: /^[a-zA-Z0-9]*$/gi,
    length: 12,
    renderCodeHead: true, //Qr code
  },
  {
    image: "/games/animalCrossingNL.webp",
    game: "animalCrossingNewLeaf",
    id: "animal-crossing-new-leaf",
    tag: "animalcrossing",
    regexFormat: /(\d{4})/g,
    regex: /^[0-9]*$/gm,
    length: 12,
  },
  {
    image: "/games/marioKart8.webp",
    game: "marioKart8Deluxe",
    id: "mario-kart-8-deluxe",
    tag: "MK8DOfficial",
    regexFormat: /(\d{4})/g,
    regex: /^[0-9]*$/gm,
    length: 12,
  },
  {
    image: "/games/supersmashbros.webp",
    game: "superSmashBros",
    id: "super-smash-bros",
    tag: "SwitchSmashBros",
    regexFormat: /(\d{4})/g,
    regex: /^[0-9]*$/gm,
    length: 12,
  },
  {
    image: "/games/superkirbyclash.webp",
    game: "superKirbyClash",
    id: "super-kirby-clash",
    tag: "kirbyinformer",
    regexFormat: /(\d{4})/g,
    regex: /^[0-9]*$/gm,
    length: 12,
  },
  {
    image: "/games/harrypotter.webp",
    game: "harryPotterWizardsUnit",
    id: "harry-potter-wizards-unit",
    tag: "HarryPotterWizs",
    regexFormat: /(\d{4})/g,
    regex: /^[0-9]*$/gm,
    length: 12,
  },
  {
    image: "/games/TheLegendOfZeldaBreathOfTheWild.webp",
    id: "the-legend-of-zelda-breath-of-the-wild",
    game: "theLegendofZeldaBreathofTheWild",
    tag: "ZeldaUniverse",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
  },

  {
    image: "/games/fornite.webp",
    id: "fortnite",
    game: "fortnite",
    tag: "FortniteGame",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
  },

  {
    image: "/games/fifa21.webp",
    id: "fifa-21-edition-essentielle",
    game: "fIFA21EditionEssentielle",
    tag: "EASPORTSFIFA",
    regexFormat: /(\d{4})/g,
  },

  {
    image: "/games/pokemonUnite.webp",
    id: "pokemon-unite",
    game: "pokemonUNITE",
    tag: "PokemonUnite",
    regex: /^[a-zA-Z0-9]*$/gi,
    regexFormat: /(\d{12})/g,
    length: 7,
  },
  {
    image: "/games/townShip.png",
    id: "township",
    game: "township",
    tag: "township_mobile",
    regex: /^[a-zA-Z0-9]*$/gi,
    regexFormat: /(\d{4})/g,
    length: 6,
  },
  {
    image: "/games/theWhitcher.webp",
    id: "the-witcher-monster-slayer",
    game: "theWitcherMonsterSlayer",
    tag: "thewitcherms",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{4})/g,
    length: 12,
  },
  {
    image: "/games/piggiGo.webp",
    id: "piggy-go",
    game: "piggyGo",
    tag: "CoinMasterGame",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{10})/g,
  },
  {
    image: "/games/crime_city.png",
    id: "crime-city",
    game: "crimeCity",
    tag: "CRIMECITY",
    regex: /^[0-9]*$/gm,
    regexFormat: /(\d{9})/g,
    length: 9,
  },
  {
    image: "/games/minecraftBedrock.webp",
    id: "minecraft-bedrock",
    game: "minecraftBedrock",
    tag: "Minecraft",
    regex: /^[a-zA-Z0-9]{1,12}$/gi,
    regexFormat: /(\d{12})/g,
  },
];

module.exports = games;
